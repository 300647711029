import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDFTE5iMRzQc7ugDCYnH26hkk-XmQu0e5g",
  authDomain: "chat-app-86346.firebaseapp.com",
  projectId: "chat-app-86346",
  storageBucket: "chat-app-86346.appspot.com",
  messagingSenderId: "504833267394",
  appId: "1:504833267394:web:9465175fe399dedef50d5b"
};




// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
